import { SupportedLanguages } from '@staycool/config/dist/types';
import sortBy from 'lodash/sortBy';
import { isFeatureAvailable } from 'services/features';
import { store } from '../stores/store';
import { getStoreValue } from '../stores/utils';
import { OPTIONS_LABEL_STYLE } from '../types/util';
import { COUNTRY_FLAG, MARKET_COUNTRY_FLAG } from './country';
import { getOptionsFromEnum } from './util';
import { Country } from '@staycool/location';
import { Market } from '@staycool/location';

export enum LANGUAGE {
    CANADIAN = 'ca',
    ONTARIO = 'on',
    CHILEAN = 'cl',
    ENGLISH = 'en',
    ESTONIAN = 'et',
    FINNISH = 'fi',
    GERMAN = 'de',
    ICELANDIC = 'is',
    NORWEGIAN = 'no',
    PERUVIAN = 'pe',
    RUSSIAN = 'ru',
    SPANISH = 'es',
    SWEDISH = 'sv',
    PARAGUAY = 'py',
    ECUADOR = 'ec',
    UNITEDSTATES = 'us',
    MEXICO = 'mx',
    EUROPE = 'eu',
}

export interface Language {
    title: string;
    value: LANGUAGE;
    country?: string;
    province?: string;
    country_value: string;
    l10nKey: string;
    flagPath: string;
    svgPath?: string;
}

export const languageArray = [
    {
        title: 'English',
        country: 'England',
        value: LANGUAGE.ENGLISH,
        country_value: 'en',
        l10nKey: 'en',
        flagPath: COUNTRY_FLAG.EN.png,
        svgPath: COUNTRY_FLAG.EN.svg,
    },
    {
        title: 'Canadian English',
        value: LANGUAGE.CANADIAN,
        country: 'Canada',
        country_value: 'ca',
        l10nKey: 'ca',
        flagPath: COUNTRY_FLAG[Country.CANADA].png,
        svgPath: COUNTRY_FLAG[Country.CANADA].svg,
    },
    {
        title: 'Ontario English',
        value: LANGUAGE.ONTARIO,
        country: 'Canada',
        province: 'Ontario',
        country_value: 'ca',
        l10nKey: 'on',
        flagPath: MARKET_COUNTRY_FLAG[Market.CANADA_ONTARIO].png,
        svgPath: MARKET_COUNTRY_FLAG[Market.CANADA_ONTARIO].svg,
    },
    {
        title: 'European English',
        value: LANGUAGE.EUROPE,
        country: 'Norway',
        country_value: 'no',
        l10nKey: 'eu',
        flagPath: COUNTRY_FLAG.EU.png,
        svgPath: COUNTRY_FLAG.EU.svg,
    },
    {
        title: 'Finnish',
        value: LANGUAGE.FINNISH,
        country: 'Finland',
        country_value: 'fi',
        l10nKey: 'fi',
        flagPath: COUNTRY_FLAG[Country.FINLAND].png,
        svgPath: COUNTRY_FLAG[Country.FINLAND].svg,
    },
    {
        title: 'Swedish',
        value: LANGUAGE.SWEDISH,
        country: 'Sweden',
        country_value: 'se',
        l10nKey: 'sv',
        flagPath: COUNTRY_FLAG[Country.SWEDEN].png,
        svgPath: COUNTRY_FLAG[Country.SWEDEN].svg,
    },
    {
        title: 'Icelandic',
        value: LANGUAGE.ICELANDIC,
        country: 'Iceland',
        country_value: 'is',
        l10nKey: 'is',
        flagPath: COUNTRY_FLAG[Country.ICELAND].png,
        svgPath: COUNTRY_FLAG[Country.ICELAND].svg,
    },
    {
        title: 'Estonian',
        value: LANGUAGE.ESTONIAN,
        country: 'Estonia',
        country_value: 'ee',
        l10nKey: 'et',
        flagPath: COUNTRY_FLAG[Country.ESTONIA].png,
        svgPath: COUNTRY_FLAG[Country.ESTONIA].svg,
    },
    {
        title: 'Russian',
        value: LANGUAGE.RUSSIAN,
        country: 'Russia',
        country_value: 'ru',
        l10nKey: 'ru',
        flagPath: COUNTRY_FLAG.RU.png,
        svgPath: COUNTRY_FLAG.RU.svg,
    },
    {
        title: 'Chilean Spanish',
        value: LANGUAGE.CHILEAN,
        country: 'Chile',
        country_value: 'cl',
        l10nKey: 'cl',
        flagPath: COUNTRY_FLAG[Country.CHILE].png,
        svgPath: COUNTRY_FLAG[Country.CHILE].svg,
    },
    {
        title: 'Mexican Spanish',
        value: LANGUAGE.MEXICO,
        country: 'Mexico',
        country_value: 'mx',
        l10nKey: 'mx',
        flagPath: COUNTRY_FLAG[Country.MEXICO].png,
        svgPath: COUNTRY_FLAG[Country.MEXICO].svg,
    },
    {
        title: 'Peruvian Spanish',
        value: LANGUAGE.PERUVIAN,
        country: 'Peru',
        country_value: 'pe',
        l10nKey: 'pe',
        flagPath: COUNTRY_FLAG[Country.PERU].png,
        svgPath: COUNTRY_FLAG[Country.PERU].svg,
    },
    {
        title: 'Ecuadorian Spanish',
        value: LANGUAGE.ECUADOR,
        country: 'Ecuador',
        country_value: 'ec',
        l10nKey: 'ec',
        flagPath: COUNTRY_FLAG[Country.ECUADOR].png,
        svgPath: COUNTRY_FLAG[Country.ECUADOR].svg,
    },
    {
        title: 'Spanish',
        value: LANGUAGE.SPANISH,
        country: 'Spain',
        country_value: 'es',
        l10nKey: 'es',
        flagPath: COUNTRY_FLAG.ES.png,
        svgPath: COUNTRY_FLAG.ES.svg,
    },
    {
        title: 'States English',
        value: LANGUAGE.UNITEDSTATES,
        country: 'United States of America',
        country_value: 'us',
        l10nKey: 'us',
        flagPath: COUNTRY_FLAG[Country.USA].png,
        svgPath: COUNTRY_FLAG[Country.USA].svg,
    },
];

const combinedLanguages: Record<string, string[]> = {
    [LANGUAGE.CANADIAN]: [LANGUAGE.ONTARIO],
};

export function getLanguages(): Language[] {
    const { LANGUAGES } = getStoreValue(store.environment);

    if (!isFeatureAvailable('core') && LANGUAGES) {
        return LANGUAGES.map(lang => languageArray.find(langInfo => langInfo.value === lang) as Language).filter(
            Boolean,
        );
    }
    return languageArray;
}

export function getLanguage(key: string): Language | undefined {
    return getLanguages().find(language => {
        return language.l10nKey === key || language.country_value === key;
    });
}

export const getFallbackLanguage = (): SupportedLanguages => {
    const { TRANSLATIONS_FALLBACK_LANGUAGE } = getStoreValue(store.environment);
    return (TRANSLATIONS_FALLBACK_LANGUAGE as SupportedLanguages) || 'en';
};

export function getLanguagesForDropdown(shouldCombineLanguages = false) {
    const languagesList = getLanguages();
    const fallbackLanguage = getFallbackLanguage();

    const formatLanguageName = (lang: Language) =>
        `${lang.value === fallbackLanguage ? 'Default' : lang.province || lang.country || lang.title} (${lang.value})`;
    const defaultLanguageSorter = el => (el.id === fallbackLanguage ? 1 : 2);

    if (shouldCombineLanguages) {
        const languagesToExclude = Object.values(combinedLanguages).flat(1);
        const languagesToReturn = languagesList
            .filter(lang => !languagesToExclude.includes(lang.value))
            .map(lang => {
                const languageCodeToCombine = combinedLanguages[lang.value];
                if (Boolean(languageCodeToCombine)) {
                    const languagesToCombine = languagesList.filter(lang => languageCodeToCombine.includes(lang.value));
                    return {
                        id: lang.value,
                        name: `${formatLanguageName(lang)} ${languagesToCombine.map(
                            language => ` / ${formatLanguageName(language)}`,
                        )}`,
                    };
                }
                return {
                    id: lang.value,
                    name: formatLanguageName(lang),
                };
            });
        return sortBy(languagesToReturn, defaultLanguageSorter, 'name');
    }

    return sortBy(
        getLanguages().map(lang => ({
            id: lang.value,
            name: formatLanguageName(lang),
        })),
        defaultLanguageSorter,
        'name',
    );
}

export function getLanguageByValue(value: string): Language | undefined {
    return languageArray.find(item => item.value.toLocaleLowerCase() === value.toLocaleLowerCase());
}

export function getUserLanguagesForDropdown(isB2b = false) {
    const b2bAllowedLanguages = ['us', 'es'];
    return getOptionsFromEnum(LANGUAGE, { labelStyle: OPTIONS_LABEL_STYLE.UPPERCASED })
        .filter(option => (isB2b ? b2bAllowedLanguages.includes(option.value) : true))
        .map(({ value, label }) => ({ label, value }));
}
