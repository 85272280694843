import { logger } from '@sentry/utils';
import { notification } from 'antd';
import keyBy from 'lodash/keyBy';
import { getServiceUrl, httpDelete, httpGet, httpPost, httpPut } from 'services/api';
import { store } from 'stores/store';
import { Type } from 'types/casino';

const getUrl = (url: string) => getServiceUrl('casino', url);

export async function loadTypes() {
    try {
        const url = getUrl('bo/types');
        const types = await httpGet(url);
        store.casino.types.set(types);
        store.casino.typesById.set(keyBy(types, 'id'));
    } catch (error) {
        logger.error('CasinoTypesMicroservice', 'loadTypes', error);
        notification.error({
            message: 'Error while fetching types',
        });
    }
}

export async function addType(newType: Type) {
    try {
        const url = getUrl('bo/types');
        return await httpPost<Type>(url, newType);
    } catch (error) {
        logger.error('CasinoTypesMicroservice', 'addType', error);
        notification.error({
            message: 'Error while adding new type',
        });
    }
}

export async function updateType(updatedType: Type) {
    try {
        const url = getUrl(`bo/types/${updatedType.id}`);
        return await httpPut<Type>(url, updatedType);
    } catch (error) {
        logger.error('CasinoTypesMicroservice', 'updateType', error);
        notification.error({
            message: 'Error while editing type',
        });
    }
}

export async function deleteType(typeId: number) {
    try {
        const url = getUrl(`bo/types/${typeId}`);
        await httpDelete(url);
    } catch (error) {
        logger.error('CasinoTypesMicroservice', 'deleteType', error);
        notification.error({
            message: 'Error while deleting type',
        });
    }
}
