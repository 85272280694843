import { httpDelete, httpGet, httpPost, httpPut } from 'services/api';
import { getServiceUrl } from 'services/api';
import { store } from 'stores/store';
import { Licence, Country, Province } from 'types/casino';
import { logger } from 'services/logger';
import keyBy from 'lodash/keyBy';
import { notification } from 'antd';

export const getUrl = (url: string) => getServiceUrl('casino', url);

export async function loadLicences() {
    try {
        const url = getUrl('bo/licences');
        const licences = await httpGet<Licence[]>(url);
        store.casino.licences.set(licences);
        store.casino.licencesById.set(keyBy(licences, 'id'));
    } catch (e) {
        logger.error('CasinoLicencesMicroservice', 'loadLicences', e);
        notification.error({
            message: 'Error while fetching licences',
        });
    }
}

export async function loadCountries() {
    try {
        const url = getUrl('bo/licences/countries');
        const countries = await httpGet<Country[]>(url);
        store.casino.countries.set(countries);
        store.casino.countriesByName.set(keyBy(countries, 'name'));
    } catch (e) {
        logger.error('CasinoLicencesMicroservice', 'loadCountries', e);
        notification.error({
            message: 'Error while fetching countries',
        });
    }
}

export async function updateLicence(licence: Licence) {
    try {
        const url = getUrl(`bo/licences/${licence.id}`);
        return await httpPut<Licence>(url, licence);
    } catch (error) {
        logger.error('CasinoLicencesMicroservice', 'updateLicence', error);
        notification.error({
            message: 'Error while updating licence',
        });
    }
}

export async function deleteLicence(licenceId: number) {
    try {
        const url = getUrl(`bo/licences/${licenceId}`);
        await httpDelete(url);
    } catch (error) {
        logger.error('CasinoLicencesMicroservice', 'deleteLicence', error);
        notification.error({
            message: 'Error while deleting licence',
        });
    }
}

export async function addLicence(licence: Omit<Licence, 'id'>) {
    try {
        const url = getUrl('bo/licences/');
        return await httpPost<Licence>(url, licence);
    } catch (error) {
        logger.error('CasinoLicencesMicroservice', 'addLicence', error);
        notification.error({
            message: 'Error while creating licence',
        });
    }
}

export async function loadProvinces() {
    try {
        const url = getUrl('bo/licences/provinces');
        const provinces = await httpGet<Province[]>(url);
        store.casino.provinces.set(provinces);
        store.casino.provincesByName.set(keyBy(provinces, 'name'));
    } catch (e) {
        logger.error('CasinoLicencesMicroservice', 'loadProvinces', e);
        notification.error({
            message: 'Error while fetching provinces',
        });
    }
}
