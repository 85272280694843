import { notification } from 'antd';
import { getServiceUrl, httpDelete, httpGet, httpPost, httpPut } from 'services/api';
import { BlogPost, BlogPostFilter, BlogPostFilterResult } from 'types/blog';
import { CmsCategory } from 'types/cms';
import { logger } from '../services/logger';
import { Email, Page, Translation } from '../types/l10n';

export const getUrl = url => getServiceUrl('l10n', url);

export enum BUCKET_TYPE {
    CMS = 'cms',
    CASINO = 'casino',
}

export async function getTranslations(groupPrefix: string) {
    try {
        const url = getUrl('l10n');
        const translations: Translation[] = await httpGet(url, { startswith: groupPrefix });

        return translations;
    } catch (error) {
        logger.error('L10nMicroservice', 'getTranslations', error);
        notification.error({
            message: `Error while fetching translations for ${groupPrefix}`,
        });
    }
}

export async function saveTranslations(translations: Translation[]) {
    const url = getUrl('l10n');
    await httpPost(url, translations);
}

export async function getEmails() {
    const url = getUrl('emails');
    return httpGet<Email[]>(url);
}

export async function getPages() {
    const url = getUrl('pages/index');
    return httpGet<Page[]>(url);
}

export async function getFolders(bucket: BUCKET_TYPE) {
    const url = getUrl('media');
    return httpGet(url, { bucket });
}

export async function getSnippetByKey(key: string, language = 'en') {
    const url = getUrl(`snippets/by_lang/${language}/${key}`);
    return httpGet<{ body: string }>(url);
}

export async function getBlogPosts(filter?: BlogPostFilter) {
    const url = getUrl(`posts/filter`);
    return httpPost<BlogPostFilterResult>(url, filter);
}

export async function getBlogPost(id: string) {
    const url = getUrl(`posts/${id}`);
    return httpGet<BlogPost>(url, { editMode: true });
}

export async function createBlogPost(payload: BlogPost) {
    const url = getUrl(`posts/`);
    return httpPost<BlogPost>(url, payload);
}

export async function updateBlogPost(payload: BlogPost) {
    const url = getUrl(`posts/${payload.id}`);
    return httpPut<BlogPost>(url, payload);
}

export async function deleteBlogPost(id: string) {
    const url = getUrl(`posts/${id}`);
    return httpDelete(url);
}

export async function getCategories(type: 'blog' | 'email' | 'page' | 'snippet') {
    const url = getUrl(`categories/${type}`);
    return httpGet<CmsCategory[]>(url);
}

export async function createCategory(payload: { type: 'blog' | 'email' | 'page' | 'snippet'; name: string }) {
    const url = getUrl(`categories/`);
    return httpPost<CmsCategory>(url, payload);
}
