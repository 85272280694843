import { getServiceUrl, httpGet } from 'services/api';
import { logger } from '@sentry/utils';
import { notification } from 'antd';
import { store } from 'stores/store';
import { Currency } from 'types/casino';
import keyBy from 'lodash/keyBy';
import { formatClientCurrencies } from '../../services/casino';

const getUrl = url => getServiceUrl('casino', url);

export async function loadCurrencies() {
    try {
        const url = getUrl('bo/currencies');
        const currencies = await httpGet<Currency[]>(url);
        const formattedCurrencies = formatClientCurrencies(currencies);
        store.casino.currenciesByName.set(keyBy(formattedCurrencies, 'currency'));
    } catch (error) {
        logger.error('CasinoCurrenciesMicroservice', 'loadCurrencies', error);
        notification.error({
            message: 'Error fetching currencies',
        });
    }
}
