import {
    Feature,
    Game,
    GameLabel,
    GameTheme,
    GameThemeTranslatable,
    GetGamesQuery,
    ExternalGame,
    FreeSpinGame,
    GetFreeSpinGamesQuery,
    RtpVersion,
    LobbySetting,
    FullGame,
    GameComment,
    GameChangelog,
    GameHistoryParams,
    OperatingLanguage,
    GameImage,
    NewComment,
    GameDescription,
    GameCommentsParams,
    GameCommentsData,
    Category,
} from 'types/casino';

import { logger } from '@sentry/utils';
import { getServiceUrl, httpDelete, httpGet, httpPost, httpPut } from 'services/api';
import { store } from 'stores/store';
import { notification } from 'antd';
import keyBy from 'lodash/keyBy';
import { CasinoGameFeatureEntity, CasinoTranslatableEntity } from '../services/casino';

const getUrl = url => getServiceUrl('casino', url);

export function getGamesMapping(query?: GetGamesQuery) {
    const url = getUrl('bo/games-mapping');
    return httpGet<Game[]>(url, query);
}

export function getFreeSpinGames(query?: GetFreeSpinGamesQuery) {
    const url = getUrl('bo/free-spins');
    return httpGet<FreeSpinGame[]>(url, query);
}

export async function loadLabels() {
    try {
        const url = getUrl('bo/labels');
        const labels = await httpGet<GameLabel[]>(url);
        store.casino.gameLabels.set(labels);
        store.casino.gameLabelsById.set(keyBy(labels, 'id'));
    } catch (error) {
        logger.error('CasinoMicroservice', 'loadLabels', error);
        notification.error({
            message: 'Error while fetching game labels',
        });
    }
}

export async function addLabel(label: CasinoTranslatableEntity) {
    try {
        const url = getUrl('bo/labels');
        return await httpPost<CasinoTranslatableEntity>(url, label);
    } catch (error) {
        logger.error('CasinoMicroservice', 'addLabel', error);
        notification.error({
            message: 'Error while adding game label',
        });
    }
}

export async function deleteLabel(id: number) {
    try {
        const url = getUrl('bo/labels');
        await httpDelete<void>(`${url}/${id}`);
    } catch (error) {
        logger.error('CasinoMicroservice', 'deleteLabel', error);
        notification.error({
            message: 'Error while deleting game label',
        });
    }
}

export async function updateLabel(label: CasinoTranslatableEntity) {
    try {
        const url = getUrl('bo/labels');
        return await httpPut<CasinoTranslatableEntity>(`${url}/${label.id}`, {
            name: label.name,
            translations: label.translations,
        });
    } catch (error) {
        logger.error('CasinoMicroservice', 'updateLabel', error);
        notification.error({
            message: 'Error while updating game label',
        });
    }
}

export async function loadRtpVersions() {
    try {
        const url = getUrl('bo/rtp-versions');
        const rtpVersions = await httpGet<RtpVersion[]>(url);
        store.casino.rtpVersions.set(rtpVersions);
        store.casino.rtpVersionsById.set(keyBy(rtpVersions, 'id'));
    } catch (error) {
        logger.error('CasinoMicroservice', 'loadRtpVersions', error);
        notification.error({
            message: 'Error while fetching RTP Versions',
        });
    }
}

export async function loadThemes() {
    try {
        const url = getUrl('bo/themes');
        const themes = await httpGet<GameTheme[]>(url);
        themes.sort((a, b) => a.name.localeCompare(b.name));
        store.casino.gameThemes.set(themes);
        store.casino.gameThemesById.set(keyBy(themes, 'id'));
    } catch (error) {
        logger.error('CasinoMicroservice', 'loadThemes', error);
        notification.error({
            message: 'Error while getting themes',
        });
    }
}

export async function addTheme(theme: Omit<GameThemeTranslatable, 'id'>) {
    try {
        const url = getUrl('bo/themes');
        return await httpPost<CasinoTranslatableEntity>(url, theme);
    } catch (error) {
        logger.error('CasinoMicroservice', 'addTheme', error);
        notification.error({
            message: 'Error while adding game theme',
        });
    }
}

export async function updateTheme(theme: GameThemeTranslatable) {
    try {
        const url = getUrl('bo/themes');
        return await httpPut<CasinoTranslatableEntity>(`${url}/${theme.id}`, {
            name: theme.name,
            themeOrder: theme.themeOrder,
            isVisible: theme.isVisible,
            translations: theme.translations,
        });
    } catch (error) {
        logger.error('CasinoMicroservice', 'updateTheme', error);
        notification.error({
            message: 'Error while updating game theme',
        });
    }
}

export async function deleteTheme(id: number) {
    try {
        const url = getUrl('bo/themes');
        await httpDelete<void>(`${url}/${id}`);
    } catch (error) {
        logger.error('CasinoMicroservice', 'deleteTheme', error);
        notification.error({
            message: 'Error while deleting game theme',
        });
    }
}

export async function loadFeatures() {
    try {
        const url = getUrl('bo/features');
        const features = await httpGet<Feature[]>(url);
        store.casino.gameFeatures.set(features);
        store.casino.gameFeaturesById.set(keyBy(features, 'id'));
    } catch (error) {
        logger.error('CasinoMicroservice', 'loadFeatures', error);
        notification.error({
            message: 'Error while getting features',
        });
    }
}

export async function addFeature(feature: CasinoGameFeatureEntity) {
    try {
        const url = getUrl('bo/features');
        return await httpPost<CasinoGameFeatureEntity>(url, feature);
    } catch (error) {
        logger.error('CasinoMicroservice', 'addFeature', error);
        notification.error({
            message: 'Error while adding game feature',
        });
    }
}

export async function updateFeature(feature: CasinoGameFeatureEntity) {
    try {
        const url = getUrl('bo/features');
        return await httpPut<CasinoGameFeatureEntity>(`${url}/${feature.id}`, {
            name: feature.name,
            showInCasino: feature.showInCasino,
            showInLiveCasino: feature.showInLiveCasino,
            translations: feature.translations,
        });
    } catch (error) {
        logger.error('CasinoMicroservice', 'updateFeature', error);
        notification.error({
            message: 'Error while updating game feature',
        });
    }
}

export async function deleteFeature(id: number) {
    try {
        const url = getUrl('bo/features');
        return await httpDelete<string>(`${url}/${id}`);
    } catch (error) {
        logger.error('CasinoMicroservice', 'deleteFeature', error);
        notification.error({
            message: 'Error while deleting game feature',
        });
    }
}

export async function getAvailableGamesByProviderId(
    providerId: number,
    offset = 0,
    pageSize = 10,
    searchTerm = '',
    status?: string,
) {
    try {
        const url = getUrl(`bo/available-games/${providerId}`);
        return await httpGet<{ externalGames: ExternalGame[]; count: number }>(url, {
            offset,
            pageSize,
            searchTerm,
            status,
        });
    } catch (error) {
        logger.error('CasinoMicroservice', 'getAvailableGamesByProviderId', error);
        notification.error({
            message: `Error while getting available games by provider ID ${providerId}`,
        });
    }
}

export async function loadGameList(providerId: number) {
    const url = getUrl(`bo/game-list/${providerId}`);
    try {
        return await httpGet<ExternalGame[]>(url);
    } catch (error) {
        notification.error({ message: `Failed to loadGameList:`, description: error.message });
        logger.error('CasinoMicroservice', 'loadGameList', error);
    }
}

export async function refreshProviderGames() {
    try {
        const url = getUrl('bo/refresh-provider-games');
        return await httpGet<void>(url);
    } catch (error) {
        notification.error({ message: 'Failed to refreshProviderGames:', description: error.message });
        logger.error('CasinoMicroservice', 'refreshProviderGames', error);
    }
}

export async function getTypesByLicenceId(id: number) {
    try {
        const url = getUrl(`bo/types/${id}`);
        return await httpGet(url);
    } catch (error) {
        notification.error({ message: 'Failed to getTypesByLicenceId:', description: error.message });
        logger.error('CasinoMicroservice', 'getTypesByLicenceId', error);
    }
}

export async function getGameById(gameId: number) {
    try {
        const url = getUrl(`bo/games/${gameId}`);
        return await httpGet<FullGame>(url);
    } catch (error) {
        notification.error({ message: 'Failed to getGameById:', description: error.message });
        logger.error('CasinoMicroservice', 'getGameById', error);
    }
}

export async function getGamesBySearchString<T>(params: {
    searchString: string;
    fields: string[];
    offset: number;
    limit: number;
}) {
    try {
        const url = getUrl(`bo/games-by-name`);
        return await httpGet<T[]>(url, params);
    } catch (error) {
        notification.error({ message: 'Failed to getGamesBySearchString:', description: error.message });
        logger.error('CasinoMicroservice', 'getGamesBySearchString', error);
    }
}

export async function getGamesByGroupIds<T>(params: { fields: string[]; groupIds: number[] }) {
    try {
        const url = getUrl(`bo/games-by-group`);
        return await httpGet<T[]>(url, { groupIds: params.groupIds.join(','), fields: params.fields });
    } catch (error) {
        notification.error({ message: 'Failed to getGamesByGroupIds:', description: error.message });
        logger.error('CasinoMicroservice', 'getGamesByGroupIds', error);
    }
}

export async function editGameById(gameId: number, game: FullGame) {
    try {
        const url = getUrl(`bo/games/${gameId}`);
        return await httpPut<FullGame>(url, game);
    } catch (error) {
        notification.error({ message: 'Failed to editGameById:', description: error.message });
        logger.error('CasinoMicroservice', 'editGameById', error);
    }
}

export async function addGame(game: FullGame) {
    try {
        const url = getUrl(`bo/games`);
        return await httpPost<FullGame>(url, game);
    } catch (error) {
        notification.error({ message: 'Failed to addGame:', description: error.message });
        logger.error('CasinoMicroservice', 'addGame', error);
    }
}

export async function loadOperatingLanguages() {
    try {
        const url = getUrl(`bo/operating-languages`);
        return await httpGet<OperatingLanguage[]>(url);
    } catch (error) {
        notification.error({ message: 'Failed to getOperatingLanguages:', description: error.message });
        logger.error('CasinoMicroservice', 'loadOperatingLanguages', error);
    }
}

export async function loadGameDescription(gameId: number) {
    try {
        const url = getUrl(`bo/descriptions/${gameId}`);
        return await httpGet<GameDescription>(url);
    } catch (error) {
        if (error?.status === 404 || error?.message?.includes('404')) {
            return null;
        }
        notification.error({ message: 'Failed to loadGameDescription:', description: error.message });
        logger.error('CasinoMicroservice', 'loadGameDescription', error);
    }
}

export async function getLobbySettings() {
    try {
        const url = getUrl('bo/lobby-settings');
        return await httpGet<LobbySetting[]>(url);
    } catch (error) {
        notification.error({ message: 'Failed to get lobby settings', description: error.message });
        logger.error('CasinoMicroservice', 'getLobbySettings');
    }
}

export async function updateLobbySetting(lobbySetting: Partial<LobbySetting>) {
    try {
        const url = getUrl(`bo/lobby-settings/${lobbySetting.id}`);
        return await httpPut<LobbySetting>(url, lobbySetting);
    } catch (error) {
        notification.error({ message: `Failed to update ${lobbySetting.name}`, description: error.message });
        logger.error('CasinoMicroservice', 'updateLobbySetting');
    }
}

export async function getGameHistoryChanges(id: number, query: GameHistoryParams) {
    try {
        const url = getUrl(`bo/game-history/${id}`);
        return await httpGet<GameChangelog>(url, query);
    } catch (error) {
        notification.error({ message: `Failed to get history changes`, description: error.message });
        logger.error('CasinoMicroservice', 'getGameHistoryChanges');
    }
}

export async function loadGameComments(id: number, params?: GameCommentsParams) {
    try {
        const url = getUrl(`bo/comments/${id}`);
        return await httpGet<GameCommentsData>(url, params);
    } catch (error) {
        notification.error({ message: 'Failed to loadGameComments:', description: error.message });
        logger.error('CasinoMicroservice', 'loadGameComments', error);
    }
}

export async function addGameComment(comment: NewComment) {
    try {
        const url = getUrl(`bo/comments`);
        return await httpPost<GameComment>(url, comment);
    } catch (error) {
        notification.error({ message: 'Failed to addGameComment:', description: error.message });
        logger.error('CasinoMicroservice', 'addGameComment', error);
    }
}

export async function editGameComment(id: number, comment: GameComment) {
    try {
        const url = getUrl(`bo/comments/${id}`);
        return await httpPut<GameComment>(url, comment);
    } catch (error) {
        notification.error({ message: 'Failed to editGameComment:', description: error.message });
        logger.error('CasinoMicroservice', 'editGameComment', error);
    }
}

export async function deleteGameComment(id: number) {
    try {
        const url = getUrl(`bo/comments/${id}`);
        return await httpDelete<GameComment>(url);
    } catch (error) {
        notification.error({ message: 'Failed to deleteGameComment:', description: error.message });
        logger.error('CasinoMicroservice', 'deleteGameComment', error);
    }
}

export async function addImage(image: GameImage) {
    try {
        const url = getUrl(`bo/game-images`);
        return await httpPost<GameImage>(url, image);
    } catch (error) {
        notification.error({ message: 'Failed to addImage:', description: error.message });
        logger.error('CasinoMicroservice', 'addImage', error);
    }
}

export async function loadGameImages() {
    try {
        const url = getUrl('bo/game-images');
        const gameImages = await httpGet<GameImage[]>(url);
        store.casino.gameImages.set(gameImages);
        store.casino.gameImagesByGameId.set(keyBy(gameImages, 'gameId'));
    } catch (error) {
        notification.error({ message: 'Failed to loadGameImages:', description: error.message });
        logger.error('CasinoMicroservice', 'loadGameImages', error);
    }
}

export async function getGameImageByGame(gameId: number, type: string) {
    try {
        const url = getUrl(`bo/game-images/${gameId}`);
        return await httpGet<GameImage>(url, { type });
    } catch (error) {
        if (error?.status === 404 || error?.message?.includes('404')) {
            return null;
        }
        notification.error({ message: 'Failed to getGameImageByGame:', description: error.message });
        logger.error('CasinoMicroservice', 'getGameImageByGame', error);
    }
}

export async function loadCategories() {
    try {
        const url = getUrl('bo/categories');
        return await httpGet<Category[]>(url);
    } catch (error) {
        logger.error('CasinoMicroservice', 'loadCategories', error);
        notification.error({
            message: 'Error while getting categories',
        });
    }
}

export async function updateCategory(category: Category) {
    try {
        const url = getUrl('bo/categories');
        return await httpPut<Category>(`${url}/${category.id}`, category);
    } catch (error) {
        logger.error('CasinoMicroservice', 'updateCategory', error);
        notification.error({
            message: 'Error while updating category',
        });
    }
}

export async function getGameImageByGameIds(gameIds: number[], type: string) {
    try {
        const url = getUrl('bo/game-images');
        return await httpGet<GameImage[]>(url, { gameIds: gameIds.join(','), type });
    } catch (error) {
        notification.error({ message: 'Failed to getGameImageByGameIds:', description: error.message });
        logger.error('CasinoMicroservice', 'getGameImageByGameIds', error);
    }
}
