import { SplashScreen } from 'components/splash-screen/SplashScreen';
import { System } from 'components/system/System';
import { Keycloak } from 'components/keycloak/Keycloak';
import React, { useEffect } from 'react';
import { initKeycloak } from 'services/keycloak';
import { logger } from 'services/logger';
import { loadEnvironment } from 'services/util';
import { store } from 'stores/store';
import { useStore } from 'stores/utils';
import { App } from './App';
import { initSentry } from './services/sentry';
import { loadFeatures } from './services/features';
import theme from 'services/theme';
import { GlobalStyles } from 'style/global';
import { ConfigProvider } from 'antd';

export function Bootstrap() {
    const [isBootstrapped, setIsBootstrapped] = useStore(store.isBootstrapped);

    useEffect(() => {
        bootstrap();
    }, []);

    async function bootstrap() {
        try {
            await Promise.all([loadFeatures(), loadEnvironment()]);
            initKeycloak(); // these use environment
            initSentry(); // we don't need to wait for sentry
        } catch (error) {
            logger.error('Bootstrap', 'bootstrap', error);
        }
        setIsBootstrapped(true);
    }

    if (isBootstrapped) {
        return (
            <ConfigProvider componentSize="large" theme={theme}>
                <GlobalStyles />
                <Keycloak>
                    <App />
                    <System />
                </Keycloak>
            </ConfigProvider>
        );
    }
    return <SplashScreen />;
}
