import { httpGet, httpDelete, httpPut, httpPost } from 'services/api';
import { getServiceUrl } from 'services/api';
import { store } from 'stores/store';
import { Provider, ProviderLink, Studio, Subprovider } from 'types/casino';
import { notification } from 'antd';
import { logger } from 'services/logger';
import keyBy from 'lodash/keyBy';

export const getUrl = (url: string) => getServiceUrl('casino', url);

export async function loadProviders() {
    try {
        const url = getUrl('bo/providers');
        const providers = await httpGet<Provider[]>(url);
        store.casino.providers.set(providers);
        store.casino.providersById.set(keyBy(providers, 'id'));
        store.casino.providersByName.set(keyBy(providers, 'name'));
    } catch (e) {
        logger.error('CasinoProvidersMicroservice', 'loadProviders', e);
        notification.error({
            message: `Error while fetching providers: ${e}`,
        });
    }
}

export async function addProvider(provider: Partial<Provider>) {
    try {
        const url = getUrl(`bo/providers`);
        return await httpPost<Provider>(url, provider);
    } catch (e) {
        logger.error('CasinoProvidersMicroservice', 'addProvider', e);
        notification.error({
            message: `Error while adding provider: ${e}`,
        });
    }
}

export async function editProvider(provider: Provider) {
    try {
        const url = getUrl(`bo/providers/${provider.id}`);
        return await httpPut<Provider>(url, provider);
    } catch (e) {
        logger.error('CasinoProvidersMicroservice', 'editProvider', e);
        notification.error({
            message: `Error while editing provider: ${e}`,
        });
    }
}

export async function deleteProvider(providerId: number) {
    try {
        const url = getUrl(`bo/providers/${providerId}`);
        return await httpDelete<Provider>(url);
    } catch (e) {
        logger.error('CasinoProvidersMicroservice', 'deleteProvider', e);
        notification.error({
            message: `Error while deleting provider: ${e}`,
        });
    }
}
export async function getProviderById(id: number) {
    try {
        const url = getUrl(`bo/providers/${id}`);
        return await httpGet<Provider>(url);
    } catch (e) {
        logger.error('CasinoProvidersMicroservice', 'getProviderById', e);
        notification.error({
            message: `Error while getting provider by id: ${e}`,
        });
    }
}

export async function loadProviderLinks(providerId: number) {
    try {
        const url = getUrl(`bo/providers/${providerId}/provider-links`);
        return await httpGet<ProviderLink[]>(url);
    } catch (e) {
        logger.error('CasinoProvidersMicroservice', 'loadProviderLinks', e);
        notification.error({
            message: `Error while fetching provider-links: ${e}`,
        });
    }
}

export async function deleteProviderLink(providerLinkId: number) {
    try {
        const url = getUrl(`bo/provider-links/${providerLinkId}`);
        return await httpDelete<void>(url);
    } catch (e) {
        logger.error('CasinoProvidersMicroservice', 'deleteProviderLink', e);
        notification.error({
            message: `Error while deleting provider-link: ${e}`,
        });
    }
}

export async function editProviderLink(providerLink: ProviderLink) {
    try {
        const url = getUrl(`bo/provider-links/${providerLink.id}`);
        return await httpPut<ProviderLink>(url, providerLink);
    } catch (e) {
        logger.error('CasinoProvidersMicroservice', 'editProviderLink', e);
        notification.error({
            message: `Error while editing providerLink: ${e}`,
        });
    }
}

export async function addProviderLink(providerLink: Partial<ProviderLink>) {
    try {
        const url = getUrl(`bo/providers/${providerLink.providerId}/provider-links`);
        return await httpPost<ProviderLink>(url, providerLink);
    } catch (e) {
        logger.error('CasinoProvidersMicroservice', 'addProviderLink', e);
        notification.error({
            message: `Error while adding provider-link: ${e}`,
        });
    }
}

export async function loadSubproviders() {
    try {
        const url = getUrl(`bo/subproviders`);
        const subproviders = await httpGet<Subprovider[]>(url);
        store.casino.subproviders.set(subproviders);
        store.casino.subprovidersById.set(keyBy(subproviders, 'id'));
        store.casino.subprovidersByName.set(keyBy(subproviders, 'name'));
    } catch (e) {
        logger.error('CasinoProvidersMicroservice', 'loadSubproviders', e);
        notification.error({
            message: `Error while fetching subproviders: ${e}`,
        });
    }
}

export async function editSubprovider(subProvider: Subprovider) {
    try {
        const url = getUrl(`bo/subproviders/${subProvider.id}`);
        return await httpPut<Subprovider>(url, subProvider);
    } catch (e) {
        logger.error('CasinoProvidersMicroservice', 'editSubprovider', e);
        notification.error({
            message: `Error while editing subprovider: ${e}`,
        });
    }
}

export async function addSubprovider(subProvider: Partial<Subprovider>) {
    try {
        const url = getUrl(`bo/subproviders`);
        return await httpPost<Subprovider>(url, subProvider);
    } catch (e) {
        logger.error('CasinoProvidersMicroservice', 'addSubprovider', e);
        notification.error({
            message: `Error while adding subprovider: ${e}`,
        });
    }
}

export async function deleteSubprovider(id: number, providerId: number) {
    try {
        const url = getUrl(`bo/subproviders/${id}`);
        return await httpDelete<Subprovider>(url, { providerId: providerId });
    } catch (e) {
        logger.error('CasinoProvidersMicroservice', 'deleteSubprovider', e);
        notification.error({
            message: `Error while deleting subprovider: ${e}`,
        });
    }
}

export async function loadStudios() {
    try {
        const url = getUrl('bo/studio');
        const studios = await httpGet<Provider[]>(url);
        store.casino.studios.set(studios);
        store.casino.studiosById.set(keyBy(studios, 'id'));
        store.casino.studiosByName.set(keyBy(studios, 'name'));
    } catch (e) {
        logger.error('CasinoProvidersMicroservice', 'loadStudios', e);
        notification.error({
            message: `Error while fetching studios: ${e}`,
        });
    }
}

export async function editStudio(studio: Studio) {
    try {
        const url = getUrl(`bo/studio/${studio.id}`);
        return await httpPut<Studio>(url, studio);
    } catch (e) {
        logger.error('CasinoProvidersMicroservice', 'editStudio', e);
        notification.error({
            message: `Error while editing studio: ${e}`,
        });
    }
}

export async function addStudio(studio: Partial<Studio>) {
    try {
        const url = getUrl(`bo/studio`);
        return await httpPost<Studio>(url, studio);
    } catch (e) {
        logger.error('CasinoProvidersMicroservice', 'addStudio', e);
        notification.error({
            message: `Error while adding studio: ${e}`,
        });
    }
}

export async function deleteStudio(studioId: number, subproviderId) {
    try {
        const url = getUrl(`bo/studio/${studioId}`);
        return await httpDelete<Studio>(url, { subproviderId: subproviderId });
    } catch (e) {
        logger.error('CasinoProvidersMicroservice', 'deleteStudio', e);
        notification.error({
            message: `Error  while deleting studio: ${e}`,
        });
    }
}
