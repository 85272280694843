import { notification, UploadFile } from 'antd';
import { getServiceUrl, httpDelete, httpGet, httpPost, httpPostFile } from 'services/api';
import { logger } from 'services/logger';
import { CmsTranslationsSearchFilters } from 'types/translation';
import {
    CmsTranslation,
    CmsTranslationHistory,
    MissingTranslation,
    TranslationSuggestedRedirectKey,
} from '../../types/cms';

export const getUrl = (url): string => getServiceUrl('cms', url);

export async function getAllTranslations(filters: CmsTranslationsSearchFilters) {
    const url = getUrl('translations/all');
    return await httpGet<CmsTranslation[]>(url, filters);
}

export async function getTranslationByKey(translationKey: string) {
    try {
        const url = getUrl(`translations/translation/${translationKey}`);
        return await httpGet<CmsTranslation>(url);
    } catch (error) {
        logger.error('CmsTranslationsMicroservice', 'getTranslationByKey', error);
        notification.error({
            message: 'An error occured while fetching translations',
        });
    }
}

export async function getTranslationsByKeys(translationKeys: string[]) {
    try {
        const url = getUrl(`translations/translation`);
        return await httpGet<CmsTranslation[]>(url, { key__in: translationKeys.join(',') });
    } catch (error) {
        logger.error('CmsTranslationsMicroservice', 'getTranslationsByKeys', error);
        notification.error({
            message: 'An error occured while fetching translations',
        });
    }
}

export async function getTranslationKeys(filterText: string) {
    try {
        const url = getUrl(`translations/keys`);
        return await httpGet<TranslationSuggestedRedirectKey[]>(url, { filterText });
    } catch (error) {
        logger.error('CmsTranslationsMicroservice', 'getTranslationKeys', error);
        notification.error({
            message: 'An error occured while fetching translation keys',
        });
    }
}
export async function getExportedTranslations(params: { translationKeys: string[] }) {
    const url = getUrl('translations/csv/export');
    return await httpGet<string>(url, params);
}

export async function upsertTranslationTexts(translations: Pick<CmsTranslation, 'id' | 'text'>[]) {
    const url = getUrl('translations/upsert-translation-texts');
    return await httpPost<CmsTranslation[]>(url, translations);
}

export async function uploadTranslationFile(file: UploadFile) {
    const url = getUrl('translations/csv/import');
    const formData = new FormData();
    formData.append('file', file as unknown as Blob);
    return await httpPostFile<{ message: string }>(url, formData);
}

export async function createNewTranslation(translations: (Pick<CmsTranslation, 'text'> & { id: string })[]) {
    const url = getUrl(`translations/translation`);
    return await httpPost<CmsTranslation[]>(url, translations);
}

export async function deleteTranslation(translationKey: string) {
    const url = getUrl(`translations/translation/${translationKey}`);
    return await httpDelete<{ message: string }>(url);
}

export async function getTranslationHistory(translationId: number) {
    const url = getUrl(`translations/history/${translationId}`);
    return await httpGet<CmsTranslationHistory[]>(url);
}

export async function getAllMissingTranslations(filters: { searchText?: string; expiredTranslation?: boolean }) {
    const url = getUrl('translations/missing/list');
    return await httpGet<MissingTranslation[]>(url, filters);
}

export async function importMissingTranslations(keys: string[]) {
    const url = getUrl('translations/missing/import');
    return await httpPost<CmsTranslation[]>(url, keys);
}
