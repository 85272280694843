import { AnyObject, TNode } from '@udecode/plate-core';
import { stringify } from 'query-string';
import {
    getServiceUrl,
    httpGet,
    httpPostBinaryFile,
    httpPost,
    httpPut,
    httpDelete,
    httpGetBinaryFile,
} from 'services/api';
import { NotificationsParams, NotificationWithBetShop } from '@staycool/retail-types/notification';
import { BetShop, BetShopTicketReport } from '@staycool/retail-types/bet-shop';
import { BetShopGroup, GetBetShopGroupResponse } from '@staycool/retail-types/bet-shop-group';
import { Settings as RetailSettings } from '@staycool/retail-types/settings';
import { KioskReport, ExtendedTerminal, Terminal, TerminalStatus, TerminalType } from '@staycool/retail-types/terminal';
import { Transaction, TransactionType } from '@staycool/retail-types/transaction';
import { BetShopSettings, PrintSettings, Settings } from '@staycool/retail-types/bet-shop-settings';
import { Session, SessionReport } from '@staycool/retail-types/session';
import { Ticket, TicketReport, TicketResponse } from '@staycool/retail-types/ticket';
import { Permission } from '@staycool/retail-types/permission';
import { OtcUser } from '@staycool/retail-types/otc-user';
import { ExtendedVoucher, VoucherReport } from '@staycool/retail-types/voucher';
import { CashboxReport } from '@staycool/retail-types/cashbox-reports';
import { IntegrityCheck } from '@staycool/retail-types/integrity-check';
import { ActionLog } from '@staycool/retail-types/action-log';
import { ArtifactTagHarborResponse } from '@staycool/retail-types/harbor';
import { notification } from 'antd';
import { Ticket as RedeemedTicket, Voucher as RedeemedVoucher } from '@staycool/retail-types/sst';
import startCase from 'lodash/startCase';
import isArray from 'lodash/isArray';
import { getReportTimestamp } from '../services/retail';
import { UserGroup, UserGroupList } from '@staycool/retail-types/user-group';

export const getUrl = url => getServiceUrl('retail-proxy', url);

async function handleProxyExceptionInternal<T = any>(
    httpMethod: (url: any, params?: any) => Promise<T>,
    callerName: string,
    url: string,
    params?: any,
    supressErrorNotification?: boolean,
) {
    let response;
    try {
        response = await httpMethod(url, params);
    } catch (e) {
        if (!supressErrorNotification) {
            if (e?.errors?.length) {
                for (const error of e.errors) {
                    if (error.message) {
                        notification.error({ message: error.message });
                    }
                }
            } else if (e.message) {
                handleDefaultErrorMessage(e.message);
            } else {
                notification.error({ message: `Failed to ${startCase(callerName)}` });
            }
        }
        throw e;
    }
    return response as Promise<T>;
}

export async function handleProxyException<T = any>(
    httpMethod: (url: any, params?: any) => Promise<T>,
    callerName: string,
    url: string,
    params?: any,
) {
    return handleProxyExceptionInternal(httpMethod, callerName, url, params, false);
}

export async function handleProxyExceptionSilent<T = any>(
    httpMethod: (url: any, params?: any) => Promise<T>,
    callerName: string,
    url: string,
    params?: any,
) {
    return handleProxyExceptionInternal(httpMethod, callerName, url, params, true);
}

function handleDefaultErrorMessage(message: string) {
    const isPermissionError = message?.includes('403') || message?.toLowerCase().includes('access denied');
    isPermissionError
        ? notification.error({ message: 'Access permission required' })
        : notification.error({ message: message });
}

interface Response<T> {
    items: T[];
    total: number;
}

export interface ResponseWithSummary<T> extends Response<T> {
    summary?: Record<string, any>[];
}

export function createUser(user: OtcUser) {
    const url = getUrl(`users/create`);
    return handleProxyException(httpPost, 'createUser', url, user);
}

export function updateUserActivityStatus(id, enabled) {
    return handleProxyException(httpPut, 'updateUserActivityStatus', getUrl(`users/${id}`), { enabled });
}

export function updateUser(record: OtcUser) {
    const { id, ...user } = record;
    return handleProxyException(httpPut, 'updateUser', getUrl(`users/${id}`), user);
}

export function getUser(id: OtcUser['id']) {
    return handleProxyException(httpGet<OtcUser>, 'getUser', getUrl(`users/${id}`));
}

export function getNotifications(params: NotificationsParams) {
    return handleProxyException(
        httpGet<Response<NotificationWithBetShop>>,
        'getNotifications',
        getUrl(`notification`),
        params,
    );
}

/** @deprecated should be called through b/e svc */
export function getTerminals(params: Filter) {
    return handleProxyException(httpPost<Response<ExtendedTerminal>>, 'getTerminals', getUrl(`terminal/list`), params);
}

export function getAvailableTerminals() {
    return handleProxyException(
        httpGet<{ id: number; hash: string }[]>,
        'getAvailableTerminals',
        getUrl('terminal/available'),
    );
}

export function getTerminalsHistory(params: Filter) {
    return handleProxyException(
        httpPost<Response<TerminalHistory>>,
        'getTerminalsHistory',
        getUrl(`terminal/history`),
        params,
    );
}

export function createTerminal(terminal: Partial<Terminal>) {
    return handleProxyException(httpPost, 'createTerminal', getUrl(`terminal/create`), terminal);
}

export function changeTerminal(terminal: Partial<Terminal>) {
    return handleProxyException(httpPut, 'changeTerminal', getUrl(`terminal/${terminal.id}`), terminal);
}

export function removeTerminalFromBetShop(betShopId: number, terminalId: number) {
    return handleProxyException(httpPut, 'deleteTerminalFromBetShop', getUrl(`bet-shop/${betShopId}/remove-terminal`), {
        terminalId,
    });
}

export function disableTerminal(id: number) {
    return handleProxyException(httpPut, 'disableTerminal', getUrl(`terminal/${id}/disable`), {});
}

export function getVouchers(filter: QueryFilter) {
    const url = getUrl('voucher/list');
    return handleProxyException(
        httpGet<ResponseWithSummary<VoucherReport>>,
        'getVouchers',
        addQueryParamsToUrl(url, filter),
    );
}

export function getBoInquiryVouchers(filter: QueryFilter) {
    const url = getUrl('voucher/list-inquiry');
    return handleProxyException(
        httpGet<ResponseWithSummary<VoucherReport>>,
        'getBoInquiryVouchers',
        addQueryParamsToUrl(url, filter),
    );
}

export enum ReportFileType {
    PDF = 'pdf',
    CSV = 'csv',
}

function getReportMIMEType(type: ReportFileType) {
    const types = {
        [ReportFileType.PDF]: 'application/pdf',
        [ReportFileType.CSV]: 'text/csv',
    };
    return types[type];
}

export async function downloadVouchersReport(type: ReportFileType, params: QueryFilter) {
    const apiUrl = getUrl(`voucher/report`);
    const response = await httpGetBinaryFile(
        addQueryParamsToUrl(apiUrl, { ...params, timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone, type }),
    );
    const blob = new Blob([response], { type: getReportMIMEType(type) });
    const downloadUrl = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = downloadUrl;
    a.target = '_blank';
    a.download = `voucher-report-${getReportTimestamp()}.${type}`;
    a.click();
    URL.revokeObjectURL(downloadUrl);
}

export async function downloadShiftsActivityReport(type: ReportFileType, params: QueryFilter) {
    const apiUrl = getUrl(`shift/activity/report`);
    const response = await httpGetBinaryFile(
        addQueryParamsToUrl(apiUrl, { ...params, timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone, type }),
    );
    const blob = new Blob([response], { type: getReportMIMEType(type) });
    const downloadUrl = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = downloadUrl;
    a.target = '_blank';
    a.download = `shift-activity-report-${getReportTimestamp()}.${type}`;
    a.click();
    URL.revokeObjectURL(downloadUrl);
}
export async function downloadShiftsBalanceReport(type: ReportFileType, params: QueryFilter) {
    let apiUrl = getUrl(`shift/balance/report`);
    apiUrl = `${apiUrl}?${stringify({ timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone, type })}`;
    const response = await httpGetBinaryFile(addQueryParamsToUrl(apiUrl, params));
    const blob = new Blob([response], { type: getReportMIMEType(type) });
    const downloadUrl = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = downloadUrl;
    a.target = '_blank';
    a.download = `shift-balance-report-${getReportTimestamp()}.${type}`;
    a.click();
    URL.revokeObjectURL(downloadUrl);
}

/** @deprecated should be called through b/e svc */
export function getTickets(filter: QueryFilter) {
    const url = getUrl('ticket/list');
    return handleProxyException(
        httpGet<ResponseWithSummary<TicketReport>>,
        'getTickets',
        addQueryParamsToUrl(url, filter),
    );
}

export function getBoInquiryTickets(filter: QueryFilter) {
    const url = getUrl('ticket/list-inquiry');
    return handleProxyException(
        httpGet<ResponseWithSummary<TicketReport>>,
        'getBoInquiryTickets',
        addQueryParamsToUrl(url, filter),
    );
}

export async function downloadTicketsReport(type: ReportFileType, filter: QueryFilter) {
    let apiUrl = getUrl(`ticket/report`);
    apiUrl = `${apiUrl}?${stringify({ timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone, type })}`;
    const response = await httpGetBinaryFile(addQueryParamsToUrl(apiUrl, filter));
    const blob = new Blob([response], { type: getReportMIMEType(type) });
    const downloadUrl = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = downloadUrl;
    a.target = '_blank';
    a.download = `ticket-report-${getReportTimestamp()}.${type}`;
    a.click();
    URL.revokeObjectURL(downloadUrl);
}

export function getKioskTransactionsReport(params: QueryFilter) {
    const url = getUrl('transaction/list');
    return handleProxyException(
        httpGet<ResponseWithSummary<KioskReport>>,
        'getKioskTransactionsReport',
        addQueryParamsToUrl(url, params),
    );
}

export async function downloadKioskTransactionsReport(type: ReportFileType, params: QueryFilter) {
    const apiUrl = getUrl(`transaction/report`);
    const response = await httpGetBinaryFile(
        addQueryParamsToUrl(apiUrl, { ...params, timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone, type }),
    );
    const blob = new Blob([response], { type: getReportMIMEType(type) });
    const downloadUrl = URL.createObjectURL(blob);
    const a = document.createElement('a');
    const reportName = {
        [ReportType.KioskActivity]: `kiosk-activity-report-${getReportTimestamp()}.${type}`,
        [ReportType.KioskBalance]: `kiosk-balance-report-${getReportTimestamp()}.${type}`,
    };
    const fileName = reportName[String(params.report)] || `kiosk-report-${getReportTimestamp()}.${type}`;
    a.href = downloadUrl;
    a.target = '_blank';
    a.download = fileName;
    a.click();
    URL.revokeObjectURL(downloadUrl);
}

export function getSessions(params: QueryFilter) {
    const url = getUrl('session/list');
    return handleProxyException(
        httpGet<ResponseWithSummary<SessionReport>>,
        'getSessions',
        addQueryParamsToUrl(url, params),
    );
}

export function getSessionTransactions(id: string, params: QueryFilter) {
    const url = getUrl(`session/${id}/transaction/list`);
    return handleProxyException(
        httpGet<Response<Transaction> & { totalInputOutput: { input: number; output: number }; session: Session }>,
        'getSessionTransactions',
        addQueryParamsToUrl(url, params),
    );
}

export async function getKiosksByBetShopId(bet_shop_id: number) {
    const { items } = await getTerminals({
        limit: 150,
        offset: 0,
        where: { bet_shop_id, type: TerminalType.Kiosk },
        whereIn: [
            { field: 'status', values: [TerminalStatus.Blocked, TerminalStatus.Enabled, TerminalStatus.PreBlocked] },
        ],
    });
    return items;
}

export async function downloadSessionTransactionsReport(id: string, type: ReportFileType) {
    const apiUrl = getUrl(`session/${id}/transaction/report`);
    const response = await httpGetBinaryFile(
        addQueryParamsToUrl(apiUrl, { timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone, type }),
    );
    const blob = new Blob([response], { type: getReportMIMEType(type) });
    const downloadUrl = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = downloadUrl;
    a.target = '_blank';
    a.download = `session-report-${id}-${getReportTimestamp()}.${type}`;
    a.click();
    URL.revokeObjectURL(downloadUrl);
}

export function getSettings() {
    const url = getUrl('settings');
    return handleProxyException(httpGet<RetailSettings['settings']>, 'getSettings', url);
}

export function saveSettings(settings: RetailSettings['settings']) {
    const url = getUrl('settings');
    return handleProxyException(httpPut<RetailSettings>, 'saveSettings', url, settings);
}

/** @deprecated should be called through b/e svc */
export function getBetShops() {
    const url = getUrl('bet-shop/all');
    return handleProxyException(httpGet<BetShop[]>, 'getBetShops', url);
}

export function getPermissions() {
    const url = getUrl('permissions/list');
    return handleProxyException(httpGet<Permission[]>, 'getPermissions', url);
}

export function getBetShopsWithPagination(params: Filter, hasNotificationWrapper = true) {
    const url = getUrl('bet-shop/list');

    if (hasNotificationWrapper) {
        return handleProxyException(httpPost<Response<BetShop>>, 'getBetShopsWithPagination', url, params);
    }

    return httpPost<Response<BetShop>>(url, params);
}

export function assignBetShopsToGroup(betShops: number[], group_id: number) {
    const url = getUrl('bet-shop/group/assign');
    return handleProxyException(httpPost<GetBetShopGroupResponse[]>, 'assignBetShopsToGroup', url, {
        group_id,
        betShops,
    });
}

export function getBetShopGroups() {
    const url = getUrl('bet-shop/group/get');
    return handleProxyException(httpGet<GetBetShopGroupResponse[]>, 'getBetShopGroups', url);
}

export function createBetShopGroup() {
    const url = getUrl(`bet-shop/group`);
    return handleProxyException(httpPost<BetShopGroup>, 'createBetShopGroup', url);
}

export function deleteBetShopGroup(group_id: number) {
    const url = getUrl(`bet-shop/group/${group_id}`);
    return handleProxyException(httpDelete<void>, 'deleteBetShopGroup', url);
}

export function getBetShopById(id: number) {
    const url = getUrl(`bet-shop/${id}`);
    return handleProxyException(httpGet<BetShop>, 'getBetShopById', url);
}

export function createNewBetShop(betShop: Partial<BetShop>, settings?: Settings) {
    const url = getUrl('bet-shop/create');
    return handleProxyException(httpPost<BetShop>, 'createNewBetShop', url, { ...betShop, settings });
}

export function disableBetShop(id: number) {
    const url = getUrl(`bet-shop/${id}/disable`);
    return handleProxyException(httpPut<BetShop>, 'disableBetShop', url, {});
}

export function changeBetShop(betShop: Partial<BetShop>) {
    return handleProxyException(httpPut<BetShop>, 'changeBetShop', getUrl(`bet-shop/${betShop.id}`), betShop);
}

export function createPermissionGroup(group: Partial<UserGroup>) {
    const url = getUrl(`/user-group`);
    return handleProxyException(httpPost<UserGroup>, 'createPermissionGroup', url, group);
}

export function deletePermissionGroup(groupId: string) {
    const url = getUrl(`user-group/${groupId}`);
    return handleProxyException(httpDelete<void>, 'deletePermissionGroup', url);
}

export function getPermissionGroupById(id: string) {
    const url = getUrl(`user-group/${id}`);
    return handleProxyException(httpGet<UserGroupList>, 'getPermissionGroupById', url);
}

export function getPermissionGroups() {
    const url = getUrl(`user-group`);
    return handleProxyException(httpGet<UserGroupList[]>, 'getPermissionGroupById', url);
}

export function changePermissionGroup(id: string, group: Partial<UserGroup>) {
    return handleProxyException(httpPut<UserGroup>, 'changePermissionGroup', getUrl(`user-group/${id}`), group);
}

export function clonePermissionGroup(id: string) {
    const url = getUrl(`user-group/${id}/clone`);
    return handleProxyException(httpPost<UserGroup>, 'clonePermissionGroup', url, {});
}

export function getPermissionGroupWithPagination(filter: QueryFilter) {
    const url = getUrl('user-group/list');
    return handleProxyException(
        httpGet<Response<UserGroupList>>,
        'getPermissionGroupWithPagination',
        addQueryParamsToUrl(url, filter),
    );
}

export function getOtcUsersWithPagination(filter: QueryFilter) {
    const url = getUrl('users/list');
    return handleProxyException(
        httpGet<Response<OtcUser>>,
        'getOtcUsersWithPagination',
        addQueryParamsToUrl(url, filter),
    );
}

export function getBetShopSettings(betShopId: number) {
    const url = getUrl(`bet-shop/settings/${betShopId}`);
    return handleProxyException(httpGet<BetShopSettings>, 'getBetShopSettings', url);
}

export function changeBetShopSettings(betShopId: number, settings: Partial<Settings & { identifier: string }>) {
    return handleProxyException(httpPut, 'changeBetShopSettings', getUrl(`bet-shop/settings/${betShopId}`), settings);
}

export async function downloadOddsSheet(params: OddsheetReport, type: ReportFileType) {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const apiUrl = getUrl(`oddsheet/report`);
    const response = await httpPostBinaryFile(apiUrl, { ...params, type, timeZone });
    const blob = new Blob([response], { type: getReportMIMEType(type) });
    const downloadUrl = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = downloadUrl;
    a.target = '_blank';
    a.download = `${params.name?.split(' ')?.join('-') || 'oddsheet'}.${type}`;
    a.click();
    URL.revokeObjectURL(downloadUrl);
}

export async function getShiftBalanceOrActivityReports(params: QueryFilter) {
    const url = getUrl('shift/list');
    return handleProxyException(httpGet, 'getShiftActivityReports', addQueryParamsToUrl(url, params));
}
export async function getExpiredSessionsByBetShopId(id: number) {
    const url = getUrl(`bet-shop/session/${id}/expired`);
    return handleProxyException(httpGet, 'getExpiredSessionsByBetShopId', url);
}

export async function getBetShopTicketsPerformance(params: QueryFilter) {
    const url = getUrl('bet-shop/tickets/list');
    return handleProxyException(
        httpGet<BetShopTicketReport[]>,
        'getBetShopTicketsPerformance',
        addQueryParamsToUrl(url, params),
    );
}

export async function getCashboxReports(params: QueryFilter) {
    const url = getUrl('cashbox-report/list');
    return handleProxyException(httpGet<CashboxReport[]>, 'getCashboxReports', addQueryParamsToUrl(url, params));
}

export async function downloadCashboxReport(type: ReportFileType, params: QueryFilter) {
    const apiUrl = getUrl(`cashbox-report`);
    const response = await httpGetBinaryFile(
        addQueryParamsToUrl(apiUrl, { ...params, timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone, type }),
    );
    const blob = new Blob([response], { type: getReportMIMEType(type) });
    const downloadUrl = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = downloadUrl;
    a.target = '_blank';
    a.download = `cash-box-report-${getReportTimestamp()}.${type}`;
    a.click();
    URL.revokeObjectURL(downloadUrl);
}

export async function downloadBetShopPerformanceReport(type: ReportFileType, params: QueryFilter) {
    const apiUrl = getUrl(`bet-shop/tickets/report`);
    const response = await httpGetBinaryFile(
        addQueryParamsToUrl(apiUrl, { ...params, timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone, type }),
    );
    const blob = new Blob([response], { type: getReportMIMEType(type) });
    const downloadUrl = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = downloadUrl;
    a.target = '_blank';
    a.download = `bet-shops-performance-report-${getReportTimestamp()}.${type}`;
    a.click();
    URL.revokeObjectURL(downloadUrl);
}

export async function getIntegrityCheck(params: QueryFilter) {
    const url = getUrl('integrity-check/list');
    return handleProxyException(httpGet<IntegrityCheck[]>, 'getIntegrityCheck', addQueryParamsToUrl(url, params));
}

export async function downloadIntegrityCheckReport(type: ReportFileType, params: QueryFilter) {
    const apiUrl = getUrl(`integrity-check/report`);
    const response = await httpGetBinaryFile(
        addQueryParamsToUrl(apiUrl, { ...params, timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone, type }),
    );
    const blob = new Blob([response], { type: getReportMIMEType(type) });
    const downloadUrl = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = downloadUrl;
    a.target = '_blank';
    a.download = `integrity-check-report-${getReportTimestamp()}.${type}`;
    a.click();
    URL.revokeObjectURL(downloadUrl);
}

export async function getRedeemedTickets(params: QueryFilter) {
    const url = getUrl('ticket/redeemed/list');
    return handleProxyException(
        httpGet<Response<RedeemedTicket>>,
        'getRedeemedTickets',
        addQueryParamsToUrl(url, params),
    );
}

export async function downloadRedeemedTicketsReport(type: ReportFileType, params: QueryFilter) {
    const apiUrl = getUrl(`ticket/redeemed/report`);
    const response = await httpGetBinaryFile(
        addQueryParamsToUrl(apiUrl, { ...params, timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone, type }),
    );
    const blob = new Blob([response], { type: getReportMIMEType(type) });
    const downloadUrl = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = downloadUrl;
    a.target = '_blank';
    a.download = `redeemed-ticket-report-${getReportTimestamp()}.${type}`;
    a.click();
    URL.revokeObjectURL(downloadUrl);
}

export async function getRedeemedVouchers(params: QueryFilter) {
    const url = getUrl('voucher/redeemed/list');
    return handleProxyException(
        httpGet<Response<RedeemedVoucher>>,
        'getRedeemedVouchers',
        addQueryParamsToUrl(url, params),
    );
}

export async function downloadRedeemedVouchersReport(type: ReportFileType, params: QueryFilter) {
    const apiUrl = getUrl(`voucher/redeemed/report`);
    const response = await httpGetBinaryFile(
        addQueryParamsToUrl(apiUrl, { ...params, timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone, type }),
    );
    const blob = new Blob([response], { type: getReportMIMEType(type) });
    const downloadUrl = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = downloadUrl;
    a.target = '_blank';
    a.download = `redeemed-voucher-report-${getReportTimestamp()}.${type}`;
    a.click();
    URL.revokeObjectURL(downloadUrl);
}

export function getTicketByTransactionId(transactionId: string) {
    const url = getUrl(`transaction/${transactionId}/ticket`);
    return handleProxyException(httpGet<TicketResponse>, 'getTicketByTransactionId', url);
}

export function getVoucherByTransactionId(transactionId: string, transactionType: TransactionType) {
    const url = getUrl(`transaction/voucher`);
    return handleProxyException(httpPost<ExtendedVoucher>, 'getVoucherByTransactionId', url, {
        transactionId,
        transactionType,
    });
}

export function getActionLog(params: Filter) {
    const url = getUrl(`action-log/list`);
    return handleProxyException(httpPost<Response<ActionLog>>, 'getActionLog', url, params);
}

export async function downloadAccountsReport(type: ReportFileType) {
    let apiUrl = getUrl(`users/report`);
    apiUrl = `${apiUrl}?${stringify({ timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone, type })}`;
    const response = await httpGetBinaryFile(apiUrl);
    const blob = new Blob([response], { type: getReportMIMEType(type) });
    const downloadUrl = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = downloadUrl;
    a.target = '_blank';
    a.download = `accounts-report-${getReportTimestamp()}.${type}`;
    a.click();
    URL.revokeObjectURL(downloadUrl);
}

export async function getMiddlewareVersionTags(includeBranchBuilds = true) {
    const url = getUrl('version/list');
    return handleProxyException(httpGet<ArtifactTagHarborResponse[]>, 'getMiddlewareVersionTags', url, {
        includeBranchBuilds,
    });
}

function objectToQueryParamString(obj: QueryFilter) {
    const queryParams: string[] = [];
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            const value = obj[key];
            if (value !== undefined) {
                isArray(value)
                    ? value.forEach(item =>
                          queryParams.push(`${encodeURIComponent(key)}[]=${encodeURIComponent(item)}`),
                      )
                    : queryParams.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
            }
        }
    }
    return queryParams.join('&');
}

export function addQueryParamsToUrl(baseUrl: string, queryParamsObj: QueryFilter) {
    const queryString = objectToQueryParamString(queryParamsObj);
    const separator = baseUrl.includes('?') ? '&' : '?';
    return `${baseUrl}${separator}${queryString}`;
}

export interface TerminalHistory {
    row_id: number;
    column_name: string;
    previous_value: string | null;
    new_value: string | null;
    updated_by: number;
    updated_at: Date;
}

export interface Filter {
    where?: { [field: string]: number | string | boolean };
    whereIn?: { field: string; values: string[] | number[] }[];
    whereILike?: { [field: string]: string };
    offset?: number;
    orderBy?: { field: string; direction: 'asc' | 'desc' };
    limit?: number;
}

export interface QueryFilter {
    [key: string]: number | string | number[] | string[];
}

export interface BetShopSettingsForm
    extends PrintSettings,
        Pick<
            Settings,
            'genericQuickAmounts' | 'shiftQuickAmounts' | 'videoPromoSrc' | 'isRacingAvailable' | 'clientName'
        > {
    associationId: number;
    identifier: string;
}

export interface OddsheetReport {
    matchIds: number[];
    categoryIds: number[];
    logo?: string;
    pageSize?: string;
    header?: TNode<AnyObject>[];
    footer?: TNode<AnyObject>[];
    name?: string;
    timeZone?: string;
}

export enum ReportType {
    Otc = 'otc',
    Kiosk = 'kiosk',
    Ticket = 'ticket',
    Voucher = 'voucher',
    ShiftActivity = 'shift_activity',
    ShiftBalance = 'shift_balance',
    BetShopPerformance = 'bet_shop_performance',
    Cashbox = 'cashbox',
    IntegritytCheck = 'integrity_check',
    AuditLog = 'audit_log',
    KioskBalance = 'kiosk_balance',
    KioskActivity = 'kiosk_activity',
    RedeemedVoucher = 'redeemed_voucher',
    RedeemedTicket = 'redeemed_ticket',
    RedeemedContestTicket = 'redeemed_contest_ticket',
}

export type LoyaltyTransaction = Transaction & { retail_ticket_id: string; product: Ticket['product'] };
