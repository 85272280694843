import { notification } from 'antd';
import { Feature } from 'feature-config';
import { loadEnabledFeatures } from 'microservices/features';
import { getStoreValue, store } from 'stores/store';
import { logger } from './logger';

export async function loadFeatures() {
    try {
        const features = await loadEnabledFeatures();
        store.features.flags.set(features);
    } catch (error) {
        if (!error.message.indexOf('failed with 404')) {
            logger.error('FeaturesService', 'loadFeatures', error);
            notification.error({ message: 'Failed to load feature flags' });
        }
    }
}

export function isFeatureAvailable(name: Feature) {
    const features = getStoreValue(store.features.flags);
    return features.includes(name);
}
